export const admin = {
  monitRequests: true,
  enrolledStudent: true,
  selectionProcess: true,
  extraRequest: true,
  faceToFaceRegistration: true
};
export const coord = {
  monitRequests: true,
  monitRequest: true
};

export const teacher = {};

export const student = {};

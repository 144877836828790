import React, { lazy, Suspense } from "react";
import { Router, Redirect, Location } from "@reach/router";
import PrivateRoute from "../../private/private";
import Loading from "../../shared/Loading";
import { AdminRoutes } from "./adminRoutes";
import { CoordRoutes } from "./coordRoutes";
import authService from "../security/authService";
import { TeacherRoutes } from "./teacherRoutes";
import { StudentRoutes } from "./studentRoutes";
const Login = lazy(() => import("../../public/login/login"));

export default function RouterImp() {
  const routes = {
    ADM: AdminRoutes,
    C: CoordRoutes,
    P: TeacherRoutes,
    A: StudentRoutes,
    NULL: <Redirect noThrow to="login" />
  };

  return (
    <Suspense fallback={<Loading />}>
      <Location>
        {({ location }) => {
          const role = authService.decodeToken().role || "NULL";
          location.role = role;
          return (
            <Router location={location}>
              <Redirect noThrow to="login" from="/" />
              <Login path="login" />
              <PrivateRoute path="/private">{routes[role]}</PrivateRoute>
            </Router>
          );
        }}
      </Location>
    </Suspense>
  );
}

import React, { lazy } from "react";
const MonitoringRequest = lazy(() =>
  import("../../private/monitoring-request")
);
const MonitoringRequestForm = lazy(() =>
  import("../../private/monitoring-request/Form")
);

export const TeacherRoutes = [
  <MonitoringRequest
    key="monitoring-request"
    path="monitoring-request"
    default
  />,
  <MonitoringRequestForm
    key="monitoring-request-form"
    path="monitoring-request/:disciplineId"
  />
];

import Axios from "axios";
import authService from "../security/authService";

const { REACT_APP_TOKEN_HEADER, REACT_APP_API_ENDPOINT } = process.env;

const HTTP = Axios.create({
  baseURL: REACT_APP_API_ENDPOINT
});

HTTP.interceptors.request.use(req => {
  if (authService.getToken()) {
    req.headers[REACT_APP_TOKEN_HEADER] = authService.getToken();
  }
  req.headers["Content-Type"] = "application/json";
  return req;
});
export default HTTP;

import React, { lazy } from "react";
const SelectionProcess = lazy(() =>
  import("../../private/selection-process/selectionProcess")
);
const MonitoringRequests = lazy(() =>
  import("../../private/monitoring-requests/monitoringRequests")
);
const EnrolledStudents = lazy(() =>
  import("../../private/enrolled-students/enrolledStudents")
);
const SelectionProcessForm = lazy(() =>
  import("../../private/selection-process/current/form")
);

export const AdminRoutes = [
  <SelectionProcess default key={1} path="selection-process" />,
  <SelectionProcessForm key={2} path="selection-process/current" />,
  <MonitoringRequests key={3} path="monitoring-requests" />,
  <EnrolledStudents key={4} path="enrolled-students" />
];

import React from "react";

import { Redirect } from "@reach/router";
import authService from "../security/authService";

export const PrivateComponent = Component => props => {
  if (!authService.isAuthenticated()) {
    return <Redirect from="" to="login" noThrow />;
  }
  return <Component {...props} />;
};

export default PrivateComponent;

import React from "react";
import "./App.sass";
import Router from "./modules/core/router/router";
import { SnackbarProvider } from "notistack";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

let theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0676B7"
    }
  }
});

function App() {
  return (
    <SnackbarProvider maxSnack={3}>
      <ThemeProvider theme={theme}>
        <Router />
      </ThemeProvider>
    </SnackbarProvider>
  );
}

export default App;

import React, { lazy } from "react";

const MonitoringRequestsCoord = lazy(() =>
  import("../../private/monitoring-requests-coord")
);
const MonitoringRequest = lazy(() =>
  import("../../private/monitoring-request")
);
const MonitoringRequestForm = lazy(() =>
  import("../../private/monitoring-request/Form")
);

export const CoordRoutes = [
  <MonitoringRequestsCoord
    default
    key="monitoring-requests"
    path="monitoring-requests"
  />,
  <MonitoringRequest key="coord-monit" path="monitoring-request" />,
  <MonitoringRequestForm
    key="monitoring-request-form"
    path="monitoring-request/:disciplineId"
  />
];

import React from "react";
import { Modal, Paper, Button } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { DatePicker, TextField } from "material-ui-formik-components";
import * as yup from "yup";
import "./extraReq.sass";
import HTTP from "../../core/http/axios";
import { API_ENDPOINTS } from "../../core/http/apiEndpoints";
import { useSnackbar } from "notistack";

export default function ExtraRequest({ handleClose, open }) {
  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = extraRequest => {
    HTTP.post(API_ENDPOINTS.admin.userException, extraRequest)
      .then(() => {
        enqueueSnackbar("solicitado com sucesso", { variant: "success" });
        handleClose();
      })
      .catch(err =>
        enqueueSnackbar(err.response.data.message, { variant: "error" })
      );
  };

  const validationSchema = yup.object().shape({
    date: yup
      .string()
      .required("Campo obrigatório")
      .nullable(),
    registration: yup.string().required("Campo obrigatório")
  });

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper className="modal-container-extra-req">
        <h3 className="title-modal">Abrir uma monitoria extra</h3>
        <Formik
          initialValues={{ date: null, registration: "" }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {props => (
            <Form>
              <div className="fields-modal">
                <Field
                  className="fields"
                  required
                  name="date"
                  inputVariant="outlined"
                  component={DatePicker}
                  label="Data de liberação"
                  format="dd/MM/yyyy"
                />
                <Field
                  className="fields"
                  required
                  variant="outlined"
                  name="registration"
                  component={TextField}
                  label="Matrícula"
                  format="dd/MM/yyyy"
                />
              </div>
              <div className="buttons-modal">
                <Button
                  onClick={() => handleClose()}
                  id="cancelButton"
                  color="primary"
                  variant="outlined"
                >
                  Cancelar
                </Button>
                <Button type="submit" color="primary" variant="contained">
                  Solicitar
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Paper>
    </Modal>
  );
}

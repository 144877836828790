import React, { useState, useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import authService from "../../core/security/authService";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { IconButton } from "@material-ui/core";

import "./header.sass";
import Buttons from "./Buttons/buttons";
import { admin, coord, student, teacher } from "./Buttons/headerConfig";

export default function Header(props) {
  const name = authService.decodeToken().name;
  // const registration = authService.decodeToken().sub;
  const [width, setWidth] = useState(0);
  const role = props.location.role;
  const headerConfig = {
    A: student,
    C: coord,
    ADM: admin,
    P: teacher
  };

  useEffect(() => {
    const resize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", resize.bind(this));
    resize();
    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  const handleLogout = () => authService.logout();
  const isMobileButtons = () => width < 1080;
  const isMobile = () => width < 600;
  return (
    <div>
      <AppBar position="static">
        <Toolbar className="header">
          {
            <Buttons
              buttonsConfig={headerConfig[role]}
              isMobile={isMobileButtons}
            />
          }
          {!isMobile() && (
            <>
              {/* <Typography color="inherit" className="text">
                {registration.toUpperCase()}
              </Typography> */}
              {!isMobileButtons() && <hr className="vertical-hr" />}
              <Typography color="inherit" className="text">
                {name.toUpperCase()}
              </Typography>
            </>
          )}
          <IconButton onClick={handleLogout} color="inherit">
            <ExitToAppIcon />
            <Typography>SAIR</Typography>
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  );
}

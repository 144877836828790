import React, { useState } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import { Button, IconButton, Popover } from "@material-ui/core";
import "./buttons.sass";
import { ROUTE_PATHS } from "../../../core/router/routePaths";
import { navigate } from "@reach/router";
import ExtraRequest from "../../ExtraRequest";
import FaceToFaceRegistration from "../../face-to-face-registration";

const Buttons = ({ buttonsConfig, isMobile }) => {
  const [popover, setPopover] = useState(null);
  const [extraRequest, setExtraRequest] = useState(false);
  const [faceTofaceRegistration, setFaceTofaceRegistration] = useState(false);

  const open = Boolean(popover);
  const id = open ? "popover" : undefined;
  const goToRoute = route => navigate(route);

  const openExtraRequest = () => setExtraRequest(true);
  const closeExtraRequest = () => setExtraRequest(false);

  const openFaceTofaceRegistration = () => setFaceTofaceRegistration(true);
  const closeFaceTofaceRegistration = () => setFaceTofaceRegistration(false);

  let allButtons = (
    <div className={isMobile() ? "buttonsMobile" : "buttons"}>
      {buttonsConfig.selectionProcess && (
        <Button
          className="button"
          color="inherit"
          onClick={() => goToRoute(ROUTE_PATHS.private.selectionProcess)}
        >
          processos seletivos
        </Button>
      )}
      {buttonsConfig.enrolledStudent && (
        <Button
          className="button"
          color="inherit"
          onClick={() =>
            goToRoute(
              ROUTE_PATHS.private.enrolledStudents + "?requestStatus=PENDING"
            )
          }
        >
          alunos inscritos
        </Button>
      )}
      {buttonsConfig.monitRequests && (
        <Button
          className="button"
          color="inherit"
          onClick={() =>
            goToRoute(
              ROUTE_PATHS.private.monitoringRequests + "?requestStatus=PENDING"
            )
          }
        >
          solicitações de monitoria
        </Button>
      )}
      {buttonsConfig.extraRequest && (
        <Button
          className="button"
          color="inherit"
          onClick={() => openExtraRequest()}
        >
          solicitações de monitoria extra
        </Button>
      )}
      {buttonsConfig.faceToFaceRegistration && (
        <Button
          className="button"
          color="inherit"
          onClick={() => openFaceTofaceRegistration()}
        >
          Inscrição presencial
        </Button>
      )}
      {/* TODO: arrumar solicitação do professor para monitoria */}
      {/* {buttonsConfig.monitRequest && (
        <Button
          className="button"
          color="inherit"
          onClick={() => goToRoute(ROUTE_PATHS.private.monitoringRequest)}
        >
          solicitar monitoria
        </Button>
      )} */}
      <ExtraRequest open={extraRequest} handleClose={closeExtraRequest} />
      <FaceToFaceRegistration
        open={faceTofaceRegistration}
        handleClose={closeFaceTofaceRegistration}
      />
    </div>
  );

  if (isMobile() && Object.keys(buttonsConfig).length) {
    const handleClick = event => {
      setPopover(event.currentTarget);
    };
    const handleClose = event => {
      setPopover(null);
    };
    return (
      <>
        <IconButton onClick={handleClick} color="inherit">
          <MenuIcon />
        </IconButton>
        <Popover
          id={id}
          className="popover"
          open={open}
          anchorEl={popover}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          {allButtons}
        </Popover>
      </>
    );
  } else {
    return allButtons;
  }
};
export default Buttons;

import React from "react";
import { Modal, Paper, Button } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { TextField } from "material-ui-formik-components";
import * as yup from "yup";
import "./styles.sass";
import HTTP from "../../core/http/axios";
import { API_ENDPOINTS } from "../../core/http/apiEndpoints";
import { useSnackbar } from "notistack";

export default function FaceToFaceRegistration({ handleClose, open }) {
  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = faceToFaceRegistration => {
    HTTP.post(
      API_ENDPOINTS.admin.CurrentStudentRequests,
      faceToFaceRegistration
    )
      .then(() => {
        enqueueSnackbar("solicitado com sucesso", { variant: "success" });
        handleClose();
      })
      .catch(err =>
        enqueueSnackbar(err.response.data.message, { variant: "error" })
      );
  };

  const validationSchema = yup.object().shape({
    registration: yup.string().required("Campo obrigatório"),
    oldDisciplineCode: yup.string().required("Campo obrigatório"),
    disciplineCode: yup.string().required("Campo obrigatório")
  });

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper className="modal-container">
        <h3 className="title-modal">Inscrever um aluno para monitoria</h3>
        <Formik
          initialValues={{
            registration: "",
            oldDisciplineCode: "",
            disciplineCode: ""
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {props => (
            <Form className="form">
              <div className="fields-modal">
                <Field
                  className="fields"
                  required
                  name="registration"
                  component={TextField}
                  variant="outlined"
                  label="Matrícula"
                  format="dd/MM/yyyy"
                />
                <Field
                  className="fields"
                  required
                  name="oldDisciplineCode"
                  component={TextField}
                  label="Disciplina antiga"
                  variant="outlined"
                  format="dd/MM/yyyy"
                />
                <Field
                  className="fields"
                  required
                  name="disciplineCode"
                  variant="outlined"
                  component={TextField}
                  label="Disciplina nova"
                  format="dd/MM/yyyy"
                />
              </div>
              <div className="buttons-modal">
                <Button
                  onClick={() => handleClose()}
                  id="cancelButton"
                  color="primary"
                  variant="outlined"
                >
                  Cancelar
                </Button>
                <Button type="submit" color="primary" variant="contained">
                  Solicitar
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Paper>
    </Modal>
  );
}

import React from "react";
import "./loading.sass";

import BarLoader from "react-spinners/BarLoader";
export default function Loading() {
  return (
    <div className="loading-container">
      <BarLoader width={250} color="#0676B7" />
    </div>
  );
}

export const API_ENDPOINTS = {
  login: "/login",
  admin: {
    selectionProcess: "admin/selection-process",
    currentSelectionProcess: "admin/selection-process/current",
    teacherRequests: "/admin/selection-process/teacher-requests",
    teacherRequestsOptions: "/admin/selection-process/teacher-requests/options",
    studentRequests: "/admin/selection-process/student-requests",
    studentRequestsOptions: "/admin/selection-process/student-requests/options",
    userException: "/admin/selection-process/userExceptions",
    CurrentStudentRequests: "/admin/selection-process/current/student-requests"
  },
  student: {
    meAvailableTa: "student/me/available-teacher-requests",
    request: "student/request",
    process: "student/process"
  },
  teacher: {
    request: "teacher/request",
    myDisciplines: "teacher/me/courses/disciplines"
  },
  coord: {
    teacherRequests: "coord/teacher-requests"
  }
};

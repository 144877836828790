import http from "../http/axios";
import JwtDecode from "jwt-decode";
import { ROUTE_PATHS } from "../router/routePaths";
import { navigate } from "@reach/router";
const {
  REACT_APP_TOKEN_HEADER: tokenHeader,
  REACT_APP_TOKEN_SESSIONSTORAGE_KEY: storageKey
} = process.env;

class AuthService {
  user = "";

  getToken() {
    return sessionStorage.getItem(storageKey);
  }
  setToken(token) {
    sessionStorage.setItem(storageKey, token);
  }
  login(user) {
    return http.post(ROUTE_PATHS.login, user).then(res => {
      if (res.status === 200) {
        const token = res.headers[tokenHeader];
        this.setToken(token);
        user = this.decodeToken();
        navigate("/private");
        window.location.reload();
      }
    });
  }

  isAuthenticated() {
    return this.getToken() !== null;
  }
  logout() {
    sessionStorage.removeItem(storageKey);
    navigate("/login");
  }
  decodeToken() {
    const token = this.getToken();
    if (token) {
      return JwtDecode(token);
    } else {
      return {};
    }
  }
}
const authService = new AuthService();
export default authService;

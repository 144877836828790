export const ROUTE_PATHS = {
  login: "/login",
  private: {
    base: "/private",
    selectionProcess: "/private/selection-process",
    currentSelectionProcess: "/private/selection-process/current",
    enrolledStudents: "/private/enrolled-students",
    monitoringRequests: "/private/monitoring-requests",
    monitoringRequest: "/private/monitoring-request"
  }
};

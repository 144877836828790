import React from "react";
import Header from "../shared/Header/header";
import PrivateComponent from "../core/router/privateComponent";
import DateFnsUtils from "@date-io/date-fns";
import ptLocale from "date-fns/locale/pt-BR";
import "./private.sass";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

const PrivateRoute = PrivateComponent(({ children, location }) => {
  return (
    <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils}>
      <div>
        <Header location={location} />
        {children}
      </div>
    </MuiPickersUtilsProvider>
  );
});

export default PrivateRoute;

import React, { lazy } from "react";
const StudentRequest = lazy(() => import("../../private/student-request"));

const StudentRequestForm = lazy(() =>
  import("../../private/student-request/form")
);

export const StudentRoutes = [
  <StudentRequest key="student-request" default />,
  <StudentRequestForm key="form" path="student-request/:requestId" />
];
